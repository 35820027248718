@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

.text-head, .text-h3, .text-h4 {
  color: black
}

body {
  margin: 0
}

@layer components {
  .page-container {
    @apply flex flex-col w-full h-screen px-8 py-6 md:px-9 md:py-7 lg:py-10 lg:px-14;
  }
}